

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonIcon,
  IonItem,
  IonFooter,
} from '@ionic/vue';
import {
  closeOutline,
  newspaperOutline,
  settingsOutline,
  barbellOutline,
  bookOutline,
  podiumOutline,
  gameControllerOutline,
  schoolOutline,
  exitOutline,
} from 'ionicons/icons';
import {useRouter} from "vue-router";
import {computed, defineComponent, ref} from "vue";
// import './home.scss'

export default defineComponent({
  name: "Home.vue",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonIcon,
    // IonItem,
    // IonFooter,
  },
  created() {
    if (localStorage.getItem('currentClass') == null || localStorage.getItem('currentClass') == undefined) {
      this.$router.push({path: 'selectclass'})
    }
  },
  setup() {
    const router = useRouter();

    const currentUser = ref(JSON.parse(localStorage.getItem('userData') || '{}'))

    const homeBackground = computed(() => {
      if (currentUser.value && currentUser.value.company && currentUser.value.company.urls && currentUser.value.company.urls.home_background) {
        return {
          '--background': 'none',
          'background': ' white url(' + currentUser.value.company.urls.home_background + ') no-repeat center',
          'background-size': 'contain'
        }
      }
      return null
    })

    function navigateTo(route) {
      router.push(route)
    }

    return {
      closeOutline,
      newspaperOutline,
      settingsOutline,
      barbellOutline,
      bookOutline,
      podiumOutline,
      gameControllerOutline,
      schoolOutline,
      exitOutline,

      router,
      currentUser,
      homeBackground,
      navigateTo
    }
  },
})
